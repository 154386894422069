import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => {
  return (
    <>
      <SEO title="About" />
      <Layout>
        <main>
          <h1 className="page-heading">A Girl Named Briana</h1>
          <section>
            <p>
              It all started in 1990... Nah, I'm not gonna start from the very
              beginning.
            </p>
            <p>
              I've worked in education-related fields for the majority of my
              life, until the last 3 years as a web developer. While working as
              a program coordinator for a high school science program, I found
              my passion for development through leading students through coding
              tutorials and decided to pursue a career in software.
            </p>
          </section>
          <section>
            <img
              alt="Briana Dacoscos, or a placeholder of Bill Murray"
              src="http://www.fillmurray.com/g/300/400"
            />
            <p>
              When I'm not coding, you can find me climbing with my friends on
              some mountain, somewhere in California.
            </p>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default AboutPage
